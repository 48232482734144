import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer";
function Projects() {
  return (
    <div>
      <div className="container">
        <div className="row row-content">
          <div className="col col-12" data-aos="fade-in" data-aos-delay="400">
            <img
              src={require("../assests/images/logo.png")}
              className="img-fluid image-align-center"
              alt="logo"
            />
          </div>
          <div className="col col-12">
            <h1
              className="text-headding text-center mb-5 mt-5"
              data-aos="fade-in"
              data-aos-delay="500"
            >
              Projects
            </h1>
          </div>
        </div>

        <div className="row row-content projects-bg">
          <div className="col col-12">
            <p
              className="text-black text-center project-title"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Leveraging our core expertise and our professional approach
              towards analysis and identification of clients&#39; needs, we
              offer comprehensive and tailor-made solutions that are unique and
              compliant with client specifications
            </p>
          </div>
        </div>

        <div className="row row-content">
          <div className="col col-12">
            <h1 className="project-text-headding text-center m-4">
              GEOTECHNICAL INVESTIGSTION &amp; DESIGN OF FOUNDATION SYSTEM
            </h1>
          </div>
          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title list-unstyled">
                HOUSING STRUCTURES
              </li>
            </ul>
            <ol
              className="project-text"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                residential apartments at Site No.5, Borewell Road, Whitefield,
                Bangalore - <strong>M/s Sanjeevini Projects</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                residential development Phase – 6 inside M/s Good Earth
                Premises, Off Mysore Road, Kambipura Village,Bengaluru -{" "}
                <strong> M/s Good Earth Eco Futures Pvt Ltd. </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Commercial Complex at St.John’s Road,, Bangalore -{" "}
                <strong> M/s Axis Concepts Capstone Ltd </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Commercial Complex at Sy. No. 494/A &amp; 494/B, Karwar, Uttara
                Kannada District, Karnataka. -{" "}
                <strong> M/s. Asiya Developers </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Residential Villa at Site No.328 at The Lake View Address,
                Veerasandra, Gollahalli Main Road, Electronic City Phase-1
                Bengaluru – 560 100 – <strong> M/s MBR Group </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                residential apartment “CASAGRAND GEDDALAHALLI” at Geddalahalli,
                Bengaluru -{" "}
                <strong>
                  {" "}
                  M/s Casa Grande Garden City Builders Private Limited{" "}
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                residential apartment “CASAGRAND CHIKKAGUBBI” at Chikkagubbi,
                Bengaluru --{" "}
                <strong>
                  {" "}
                  M/s Casa Grande Garden City Builders Private Limited{" "}
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical work for the proposed construction of Residential
                building for “ Prime Symphony” at Bidadi industrial area,
                Bangalore – <strong>M/s Prime Estates</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Residential Apartments at 6th cross, LBS Nagar, Kaggadasapura,
                Bangalore – 560 017. <strong>M/s A2G Builders</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Residential Building at Site No. 63, Kaggalipura Village,
                Uttarahalli Hobli, Bangalore.-
                <strong> M/s V M Constructions</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Commercial Building “QUEST” inside M/s Good Earth Premises, off
                Mysore Road, Kambipura Village,Bengaluru-
                <strong> M/s Good Earth Eco Futures Pvt Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Residential Apartment “Garuda Park Square” at Muniyappa Layout,
                T.C. Palya Main Road, K.R. Puram,Bengaluru-
                <strong> M/s. Garudachala Estates Pvt. Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of Club
                House inside Brick Field Gem Park, Mahalchowenhalli Village,
                Anekal Taluk, Bengaluru – 562 125. –{" "}
                <strong>M/s MBR Group</strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                INDUSTRIAL STRUCTURES
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Evaluation of soil condition below the Flagging Concrete next to
                compound wall on the rear side of M/s Supreme Overseas Exports
                India Pvt Ltd at # 44/1, 16th Cross, K.R.Road,Bangalore -{" "}
                <strong>M/s Supreme Overseas Exports India Pvt Ltd</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                Factory building at Bangalore Aerospace Park, KIADB Industrial
                area, Devanahalli Taluk, Bangalore -{" "}
                <strong> M/s SIMPLE TECH AEROSPACE PVT LTD </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed construction of
                factory building for M/s Nisha Design at Gorantla, Puttaparthi
                Road, Anantapur District, Andhra Pradesh -
                <strong> M/s Nisha Design </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of Car
                showroom at Site No.18/3, Kariyanna layout, Hebbal, Bengaluru. –{" "}
                <strong> M/s Varun Motors </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Commercial building at Plot No.81, KIADB Bangalore IT Park, Near
                Bagalur, Arebannimangala, Bangalore-{" "}
                <strong>M/s Global I P Services Pvt Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Evaluation of soil condition for the proposed construction of
                Industrial building at Private Industrial Park at Yelladekare
                Village, Hiriyur Taluk, Chitradurga District. -{" "}
                <strong> M/s Trans India Shipping Services Pvt Ltd. </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of IT
                Building at KIADB Bangalore IT Park,Arebannimangala, near
                Bagalur, Bangalore- <strong> M/s R.N. Infra Projects </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of R
                &amp; D Centre for M/s Mahindra Electric Mobility Limited at
                Plot No.12-P3, Road No.3, Hi-tech, Defence and Aerospace park,
                KIADB Industrial area, Devanahalli Village, Bengaluru North-{" "}
                <strong> M/s Mahindra Electric Mobility Limited</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Industrial Building at Block- 3 Utility Area, Site No.-52,
                Sipcot Industrial Area, Hosur ,Tamil Nadu – 635126-
                <strong> M/s. SSF Plastics India Private Limited</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Tower - 3 Building inside Manipal Hospital Premises, HAL Airport
                Road, Kodihalli, Bengaluru. –{" "}
                <strong> M/s Manipal Health Enterprises Pvt Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of New
                Retail Outlet for M/s Bharat Petroleum over all Karnataka –{" "}
                <strong> M/s Gcube Consultants, Dharwad. </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Industrial Building at Beerepalli, Hindupura Taluk, Anantapura
                District, Andhra Pradesh.-{" "}
                <strong> M/s Super Spinning Mill Limited </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Industrial building at Harohalli Industrial area, Kanakapura
                Taluk, Ramanagara District, Karnataka -{" "}
                <strong> M/s Biovitamin Pvt Ltd </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                D.G.Yard inside ICP facility management and services Pvt Ltd,
                Plot No.2A at Electronic city Phase-2, Bengaluru – 560 100 -{" "}
                <strong> M/s Mantech Engineers</strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                PUBLIC STRUCTURES
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Confirmation report on Stratification encountered and Safe
                Bearing Capacity adopted at the founding level for the proposed
                construction of bus stand and commercial complex at NEKRTC old
                bus stand area, Bellary, Karnataka –{" "}
                <strong> M/s NEKRTC. </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Rastrotthana Health Center &amp; Gym at Kundalahalli, Bangalore
                – <strong> M/s Mysore Consultants.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Blood Bank building for M/s Rastrotthana Parishath at Site No.7,
                Sunkenahalli Ward No.142(49) off Purna Chandra road, Gavipuram
                Guttahalli, Bangalore -{" "}
                <strong> M/s Mysore Consultants.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                Clock Tower near GEC-II inside Infosys Campus at Mysore,
                Karnataka -{" "}
                <strong> M/s KEF Infrastructures India Pvt Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Kitchen Building for Indira Canteen at Mariappanapalya near
                Mahakavi Kuvempu Metro Station, Bengaluru -{" "}
                <strong> M/s Bruhat Bengaluru Mahanagara Palike </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Decathlon Sports Retail at Kalena Agarhara, Bannerghatta Main
                Road, Bengaluru.-{" "}
                <strong>M/s Decathlon Sports India Pvt Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                residential quarters for KSRTC Staff at Sakleshpura, Hassan
                District, Karnataka – <strong> M/s KSRTC </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                buildings under phase-1 inside New Indian Institute of
                Technology Campus at Chikka Malligawad Village near Belur
                Industrial Area, Dharwad, Karnataka -{" "}
                <strong> M/s Suresh Goel and Associates .</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of New
                Building at Bus Stand, Mandya District, Karnataka. –{" "}
                <strong> M/s KSRTC</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Udupi Moffussil Bus Terminus at Bannanje, Udupi District,
                Karnataka. – <strong> M/s KSRTC</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of New
                Bus Stand at Byndoor Town, Kundapura Taluk, Udupi District,
                Karnataka. – <strong> M/s KSRTC </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Automated vehicle Fitness Centre and Electronic Driving Testing
                Track at Raichur, Raichur District, Karnataka -{" "}
                <strong>
                  {" "}
                  North East Karnataka Road Transport Corporation
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Additional Rooms for Boys and Girls Dormitory Building at
                Kerebilichi Village, Channagiri Taluk, Davanagere District,
                Karnataka - <strong> M/s Davanagere Nirmithi Kendra </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Residential School and Hostel building for Boys and Girl for M/s
                Karnataka Residential Educational Institutions Society at
                Ramanagara, Mandya, Mysore, Chamarajanagar, Tumkur and
                Davanagere District, Karnataka -{" "}
                <strong> M/s Chetana Consultants.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Retaining Wall at Kodagu District-{" "}
                <strong>
                  {" "}
                  Karnataka State Police Housing & Infrastructure Development
                  Corporation Limited
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                Dharmika bhavan, Survey No.10, Ward No.164 at Gururaja Layout,
                near Vidyapeeta circle, Bangalore -{" "}
                <strong> M/s Poornaprajna Vidyapeetha Prathishtana (R).</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of BBMP
                Office Complex Building at Ward-159, 1st Main, opp. Surana
                College, Kengeri, Bangalore. -{" "}
                <strong> M/s Bruhat Bengaluru Mahanagara Palike </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Tennis Court inside Balankere Park, Govindarajanagar,
                Bangalore.-{" "}
                <strong> M/s Bruhat Bengaluru Mahanagara Palike </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Computer Training Centre and Conference hall inside
                Superintendent of police campus at Miller’s Road, Bengaluru –
                560 052 for - <strong> M/s Davanagere Nirmithi Kendra</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                multi-level parking at Vijayapura bus station, Vijayapura,
                Karnataka-{" "}
                <strong>
                  M/s North East Karnataka Road Transport Corporation{" "}
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Taralabalu Samudaya Bhavana at Jagajyothi BH Road, Tarikere,
                Chikkamagaluru District, Karnataka. -{" "}
                <strong> Sri. Taralabalu Mutt</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of New
                N.E.K.R.T.C central office building at station road, Kalburgi,
                Karnataka. -{" "}
                <strong>
                  {" "}
                  M/s North East Karnataka Road Transport Corporation{" "}
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                Dhyana Mantapa inside Chikkenakoppada Shri Channaveera Sharanara
                Mutt Premises, Balaganur, Gadag, Karnataka. -{" "}
                <strong>
                  {" "}
                  Chikkenakoppada Shri Channaveera Sharanara Mutt{" "}
                </strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                BRIDGES/PAVEMENTS & FLYOVERS
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Minor Bridge at Bellara Village, Sira Taluk, Tumkur District,
                Karnataka State - <strong> M/s NeSa Engineers</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Retaining Wall on RHS at Bendaravadi, Chamarajanagara District -{" "}
                <strong> M/s Sri Bapuji Constructions (P) Ltd</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                bridge across Doodh Ganga River at Karadga – Bhoj Road, Chikkodi
                Taluk, Belagum District, Karnataka.(Bridge No.37) –{" "}
                <strong> M/s Classic Consultants, Mysore</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                Major bridge at Km 219 + 000 of NH – 234 between Banawara to
                Huliyar Section, Arsikere Taluk, Hassan District, Karnataka -{" "}
                <strong> M/s Thrimurthy Constructions </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                bridge near Gokak Falls, Gokak, Belagum District, Karnataka. –{" "}
                <strong> M/s Classic Consultants, Mysore</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                bridge at Km 13.00 on Chandevadi – Castle Rock in Joida Taluk,
                Uttara Kannada District, Karnataka.
                <strong> M/s Apt Consulting Engineering Services</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                Minor bridge at Bellara Village, Sira Taluk, Tumkur District,
                Karnataka. - <strong> M/s NeSa Engineers</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Development of Outer
                Ring Road for Hassan Town Connecting Nelamangala-Mangalore Road
                (NH-75) Via Approach Road to Proposed Hassan Airport, Arasikere
                Road (SH-68), Halebidu Road and Belur Road (NH-373) for a Length
                of 25km in Hassan District, Karnataka.-{" "}
                <strong> M/s Apt Consulting Engineering Services </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                bridge across Bheema River on Honnal – Tannur in Jewargi
                Taluk,Kalburgi District, Karnataka. .–{" "}
                <strong> M/s Classic Consultants, Mysore </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Confirmatory Geotechnical Investigation for the proposed
                construction of Major bridge across Malaprabha River at
                Bevinakoppa – Sangoli Road, Bailhongal Taluk, Belgaum District,
                Karnataka. – <strong> M/s Classic Consultants, Mysore</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Various Minor Bridges in widening to two lane with paved
                shoulders from 24 + 600 to 70 + 062 NH-173 Mudigere to Kadur
                section, Karnataka -{" "}
                <strong> M/s Apt Consulting Engineering Services</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Confirmatory Geotechnical Investigation for the proposed
                construction of Major bridge across Krishna River on Rabakavi –
                Banahatti Road, Jamakhandi Taluk, Bagalkot District, Karnataka–{" "}
                <strong> M/s Classic Consultants, Mysore </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Confirmatory Geotechnical Investigation for the proposed
                construction of Major bridge across Krishna River between Yadur
                – Kalola Village road, Chikkodi Taluk, Belagavi
                District,Karnataka–{" "}
                <strong> M/s Classic Consultants, Mysore</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Confirmatory Geotechnical Investigation for the proposed
                construction of Major bridge across Ghataprabha River at
                Davaleshwar Village, Mudhol Taluk, Bagalkot District, Karnataka.
                – <strong> M/s Classic Consultants, Mysore </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Confirmatory Geotechnical Investigation for the proposed
                construction of Major bridge across Krishna River near Kudachi
                in Rayabhaga Taluk(Connecting Maharshtra Border) Belagavi
                District,Karnataka –{" "}
                <strong> M/s Classic Consultants, Mysore </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Various Minor Bridges in widening to two lane with paved
                shoulders from 24 + 600 to 70 + 062 NH-173 Mudigere to Kadur
                section, Karnataka. Karnataka -{" "}
                <strong> M/s Apt Consulting Engineering Services</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Reconstruction to Two lane with paved shoulders from Ds.Ch. 442
                +180 (ex.km 448.00) to Ds.Ch.485+240 (ex.km 494.00) of Huliyar
                to K B Cross section and from Ds.Ch 532+100 (ex.km 533.150) to
                Ds.Ch 539+100(ex.km 540.175) from K B Cross to Nelligere section
                of NH-150A in the state of Karnataka -{" "}
                <strong> M/s Apt Consulting Engineering Services</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                retaining wall on Mangalore – Madikeri National Highway 275 near
                Madikeri Town, Madikeri District, Karnataka. . -{" "}
                <strong> M/s Apt Consulting Engineering Services</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of Box
                Bridge at KM 31+430 near Madihalla, Mudhol Taluk, Bagalkot
                District, Karnataka. . -{" "}
                <strong> M/s Apt Consulting Engineering Services</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Major Bridge at Km 67.139 on NH-169A of Thirthahalli- Udupi
                section in the state of Karnataka. . -{" "}
                <strong> M/s Apt Consulting Engineering Services</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Bridge to Netravathi river at Belluru Bailu, Yettinagadi in
                Kadir, Udyavara Village in Belthangady Taluk, Dakshina Kannada
                district,Karnataka - <strong> M/s Philip D Costa & Co</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                vertical lift type bridge on Br.No.346(Pamban Bridge) in lieu of
                existing scherzer rolling lift bridge on existing broad gauge
                line between Mandapam and Pamban Stations in Madurai Division –
                OFFSHORE SOIL INVESTIGATION –{" "}
                <strong> M/s Geomarine Consultants and Technocrats.</strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                WATER RETAINING STRUCTURES AND EARTHEN EMBANKMENT
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Sewage Treatment Plant for M/s Radisson Blu Atria Hotel at
                Palace Road, Bangalore- <strong> M/s Radisson Blu Atria</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Aqueduct (Ch: 177+325 – Ch:182+125) for Yettinahole Project at
                Gubbi Taluk, Tumkur district, Karnataka.{" "}
                <strong> M/s BSR Infratech India Ltd</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Aqueduct (Ch: 182+530 – Ch:183+210) for Yettinahole Project at
                Gubbi Taluk, Tumkur district, Karnataka -{" "}
                <strong> M/s Star Builders and Developers</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for reconstruction of Transition exit
                of RC Trough at Km : 23+370 of right bank canal of Malaprabha
                Project near Savadatti, Dharwad District, Karnataka -{" "}
                <strong> Sri S.V.Bandi, Class I Contractor</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for reshaping of the existing
                reservoir and testing of soil for its suitability inside IIHS
                new campus under Phase-I at Kengeri, Bangalore, Karnataka -{" "}
                <strong> M/s Indian Institute for Human Settlements </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Assessment of soil properties for the bund material at
                impounding reservoir constructed near Thurvihal for water supply
                to Sindhanur town, Raichur district, Karnataka –{" "}
                <strong> M/s KUIDFC</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Aqueduct (Ch: 242+665 – Ch: 243+145) for Yettinahole Project
                Package 23 at Irakasandra Lake, Koratagere Taluk, Tumkur
                district, Karnataka-{" "}
                <strong> M/s BSR Infratech India Ltd</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                20.00 LL Capacity pure water sump at Chikkakere for providing
                water supply to Anekal town from BWSSB tapping point, Anekal
                Bangalore - <strong> The Chief Officer - Anekal Town.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                50,000 ltrs capacity RCC Sump and Pump house near existing OHT
                at Kempegowdanagar, Near Doddaballapur railway station,
                Doddaballapur, Bangalore Rural District.{" "}
                <strong> -M/s Sujala Structures</strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                WINDMILL AND SOLAR PLANT
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for proposed 25 MW Solar Power PV
                Project at Radder Naganur, Nargund Taluk, Gadag District,
                Karnataka State -{" "}
                <strong> M/s Oriano Clean Energy Private Limited </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for proposed 12 MW Solar Power PV
                Project at Betageri Village, Koppal District, Karnataka State-{" "}
                <strong> M/s Oriano Clean Energy Private Limited </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigations at proposed 5 MWp Solar P V Plant
                Installation at GMR Hyderabad International airport Ltd,
                Shamshabad, Telangana State -{" "}
                <strong> M/s Daksh Energy solutions</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed Installation of Wind
                Turbine Generator at Hattarwat Wind Farm Site, Chikkodi Taluk,
                Belgaum District, Karnataka -{" "}
                <strong> M/s Enerfra Projects (India) Private Limited.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed Installation of 25MW
                Wind Turbine Generator at Kanahosahalli Village, Kudligi Taluk,
                Bellary District, Karnataka{" "}
                <strong>
                  {" "}
                  M/s Clean Max Enviro Energy Solutions Private Limited.
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical and Geophysical Investigation for proposed
                installation of 14.4 MW Solar Power PV Plant at Yargatti Hobli,
                Saundatti Taluk, Belagum District, Karnataka State.-{" "}
                <strong> M/s Sugata Infra Pvt Ltd</strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                EDUCATIONAL INSTUTIONS
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Auditorium inside Er. Permual Manimekalai College of
                Engineering, Hosur, Tamil Nadu.-{" "}
                <strong> M/s Studio Whitescape</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed construction of
                buiilding inside M/s Bangalore Management Academy School at
                Doddanakundi, Bangalore, Karnataka-{" "}
                <strong> M/s KEF Infrastructures India Pvt Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                School Building at Site No. 1405, 40th Main, 9th Cross,
                J.P.Nagar 1st Phase, Bangalore-560 078.{" "}
                <strong> M/s Jayashree Public School</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                Auditorium inside Oxford Institute of Technology Campus, Hosur
                Road, Bommanahalli, Hongasandra, Bangalore -{" "}
                <strong> M/s Oxford Institute of Technology</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical investigation for the proposed Construction of Sri
                Chaitanya Techno School in and around Bengaluru –{" "}
                <strong> M/s Gokul Constructions.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the Proposed Construction of
                School Building at Site No. 31/1, Hadosiddapura Main Road,
                Sarjapur Road, Behind R.G.A Tech Park, Bangalore -
                <strong> M/s. C.M.R Gandhi Group of Institution</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Geotechnical Investigation for the proposed construction of
                Sports Complex at Sri Dharmasthala Manjunatheshwara College of
                Engineering and Technology Campus at Sattur, Dharwad. -{" "}
                <strong>
                  {" "}
                  Shri Dharmasthala Manjunatheshwara Educational Society
                </strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                PILE AND PLATE LOAD TEST
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Static Vertical Compression Load Test on Pile for the proposed
                Construction of Residential Villas at Bannati, Coonoor, Tamil
                Nadu - <strong> M/s Alpha Associates</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Static Vertical Compression Load Test on Pile for proposed
                expansion of Laboratory building inside M/s Mylan Laboratories
                ltd at Bommasandra-Jigani Link Road, Anekal Taluk, Bangalore -{" "}
                <strong> M/s B.M.Constrotech Pvt Ltd</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Static Vertical Compression Load Test on Pile for Proposed
                Construction of 5 MLD STP at Chikka Begur, Bengaluru -{" "}
                <strong>M/s Ayyappa Infra Projects Pvt Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Plate Load test for the proposed construction of 450 bedded
                hospital in the premises of Hassan Institute of Medical science
                at Hassan district, Karnataka -{" "}
                <strong> M/s BSR Infratech India Ltd </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Static Vertical Compression Load Test on Pile for proposed
                Construction of Residential Villas at M/s Good Earth Medley,
                Mysore Road, Bangalore.-{" "}
                <strong>
                  {" "}
                  M/s Silver Route Constructions & Services Pvt Ltd.
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Pile Load test is to evaluate the Vertical Compression Load
                Test, Pull Out Test and Lateral Load Test carried out for the
                proposed 5 MWp Solar P V Plant Installation at GMR Hyderabad
                International airport Ltd, Shamshabad, Telangana State-{" "}
                <strong> M/s Daksh Energy solutions</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Plate Load test inside New KIA Motors factory building at
                Penukonda, Anantapur District, Andhra Pradesh-{" "}
                <strong> M/s ARO Facilities and Constructions Pvt Ltd.</strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                INVESTIGATION AND RESTORATION OF STRUCTURES
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Feasibility study for construction of additional floors over
                existing “Spandana Souharda Sahakari Niyamitha” building at
                Chandra layout, Bangalore-560040{" "}
                <strong> M/s Spandana Souharda Sahakari Niyamitha </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Structural Stability Assessment of Identified Ground Floor Slab
                with Newly Constructed Strong Room of Existing Commercial
                Building at 5th Main Road, Chamrajpet, Bengaluru -{" "}
                <strong> Mr. Jayant S Gauri </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Feasibility Study For Construction of Additional Floors Over
                Existing “Residential Building” at No.70, 3rd Cross, CR Layout,
                JP Nagar Ist Phase, Bangalore-560078 -{" "}
                <strong> Sri. C K Jagannatha </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Investigation of Overhead RC Water Tank with Ground Level
                Reservoir (4.0 Lakh Liters Capacity) in distress at Jal Vayu
                Vihar Apartment Owner's Association, Kammanahalli Main Road,
                Bengaluru -{" "}
                <strong>The President, Jal Vayu Vihar Apartment</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Feasibility Study for Construction of Additional Floors over the
                Existing “R.R. Towers” Building at Bannerghatta Main Road,
                Bengaluru.-{" "}
                <strong> M/s. Test Yantra Software Solutions (I) Pvt.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Feasibility Study for Proposed Construction of Additional Floors
                (i.e. Third & Fourth floor) over the Existing Office Building at
                5th Block, Koramangala Bengaluru-{" "}
                <strong> M/s. D'insignia Architects,</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Quality Assessment of the Existing Identified Region of EC2
                Building of M/s. Hewlett Packard Enterprises (HPE) Corporate
                Office at Electronic City, Bengaluru -{" "}
                <strong>
                  {" "}
                  M/s. Imprints India Architecture & Structural Engineering
                </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Assessment of Structural Soundness of Existing Building at 9th
                Cross, Ring Road, Peenya 1st Stage, Peenya Industrial Estate,
                Peenya III Phase, Bengaluru -{" "}
                <strong> M/s. Jindo Chemical Solution Pvt. Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Evaluation of Structural Stability of Existing “Jyothi Mahal
                Building” Located at St.Marks Road, Bengaluru-560001-{" "}
                <strong> The Brothers of Holy Cross</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Structural Stability Assessment of Identified Buildings in the
                Premises of M/s. Milltech Machinery Pvt. Ltd., at Bommasandra
                Industrial Area, Bengaluru{" "}
                <strong> M/s. Milltech Machinery Pvt. Ltd</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Evaluation of Structural Soundness of Existing Commercial
                Building of Press Trust of India, Millers Tank Road, Vasanth
                Nagar, Bengaluru- <strong> M/s. Press Trust of India</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Evaluation of Quality of Construction & Structural Stability of
                Under-Construction School/Residential Building at Elampillai,
                Salem District, Tamil Nadu -{" "}
                <strong> Mrs MALATHI RAMALINGAM</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Refurbishment Works Carried Out at Building-1 of Block-A, B, C
                in the Premises of M/s. Sap Labs India Pvt. Ltd.,White Field,
                Bangalore - <strong> M/S. Sap Labs India Pvt. Ltd. </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Proof Checking Of Structural Designs and Drawings Of R C
                Foundation For Vertical Tank “20 KL L CO2 Foundation” at
                Premises M/s. Syngene International Ltd, Biocon Park,
                Bommasandra Industrial Area, Bengaluru -{" "}
                <strong> M/s. Syngene International Ltd </strong>
              </li>
            </ol>
          </div>

          <div className="col col-12 project1-bg">
            <ul data-aos="fade-up" data-aos-delay="400">
              <li className="text-center m-5 project-title">
                NON DESTRUCTIVE TEST ON R C STRUCTURES
              </li>
            </ul>
            <ol className="project-text">
              <li data-aos="fade-up" data-aos-delay="400">
                Non-destructive test on identified RC columns at terrace level
                of existing Garuda Mall building at Yelahanka, Bengaluru-{" "}
                <strong>M/s. BGS Construction Pvt. Ltd.</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Non-Destructive & Semi-Destructive Tests on RC members at
                Identified regions at Existing Building of M/S. Remidex Pharma
                Pvt. Ltd., B-249/250, 6th Main Road, 2nd Stage, Peenya
                Industrial Area Phase IV, Peenya, Bengaluru-{" "}
                <strong> M/s. SyZyGy Structural Consultants</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Non-destructive test on identified RC columns at upgradation of
                (SC) MDRS to (SC) MDR PUC Complex at Doddabadagere Village,
                Harohalli Hobli, Kanakapura Taluk, Ramanagara district,
                Karnataka - <strong> Mr. G.V.Gopal Reddy</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Covermeter Studies on Identified RC Members of Cafeteria
                Building at Premises of M/S. Hewlett Packard Enterprises (HPE),
                Whitefeild Road, Mahadevapura Post, Bengaluru -{" "}
                <strong> M/S. RSP DESIGN CONSULTANTS (I) PVT LTD </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Pull-Out Test on Rebar Using Anchor Grout Embedded in Concrete
                at RV Engineering College “Metro Station” Under Construction at
                Mysore Road, Bengaluru - <strong> M/s. Chevetol. </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Non-Destructive Tests (Ultrasonic Pulse Velocity Test, Rebound
                Hammer Test & Covermeter Study) for identified RC members of
                Residential building of Mr. Vinay Seethram at Banashankari,
                Bengaluru.- <strong> Mr. Vinay Seethram </strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Semi Destructive core test at Founding level of existing Masjid
                - E-Rahimiya Mosque building at Mysuru Road, Bengaluru –{" "}
                <strong> M/s Central Muslim Association</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Strength of in-situ concrete and to identify reinforcement
                disposition, Probable peripheral diameter of rebar & thickness
                of cover concrete on Identified RC Parapet Wall at Existing EC2
                Building Of M/S. Hewlett Packard Enterprises (HPE) Corporate
                Office at Electronic City Phase-1, Bengaluru –{" "}
                <strong> M/s Metle</strong>
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                Carrying Out Ultrasonic Pulse Velocity Test and Rebound Hammer
                Test on Structural Members of Identified Minor Bridge at
                CH.73+800 KM and Major Bridge at CH.75+152KM for Improvement of
                Yelahanka to AP - Border SH-09 Road Project from 13+800 KM to
                89+417 of SH-09 in the State of Karnataka under DBFOT – (VGF)
                TOLL{" "}
                <strong>
                  M/s. Yelahanka – AP Border Tollways Pvt. Ltd., (YAPBTPL)
                </strong>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Projects;
